<template>
  <div>
    <a-spin :spinning="isLoading">
      <sdPageHeader title="Müşteri Listesi" />
      <Main>
        <a-row
          type="flex"
          justify="center"
          :gutter="16"
          align="top"
        >
          <a-col :span="24">
            <sdCards headless>
              <a-table
                :data-source="customers"
                :columns="columns"
                row-key="id"
              >
                <template
                  #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                >
                  <div style="padding: 8px">
                    <a-input
                      ref="searchInput"
                      :placeholder="`Search ${column.dataIndex}`"
                      :value="selectedKeys[0]"
                      style="width: 188px; margin-bottom: 8px; display: block"
                      @change="
                        e =>
                          setSelectedKeys(
                            e.target.value ? [e.target.value] : []
                          )
                      "
                      @pressEnter="
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                      "
                    />
                    <a-button
                      type="primary"
                      size="small"
                      style="width: 90px; margin-right: 8px"
                      @click="
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                      "
                    >
                      <template #icon>
                        <SearchOutlined />
                      </template>
                      Search
                    </a-button>
                    <a-button
                      size="small"
                      style="width: 90px"
                      @click="handleReset(clearFilters)"
                    >
                      Reset
                    </a-button>
                  </div>
                </template>
                <template #filterIcon="filtered">
                  <search-outlined
                    :style="{ color: filtered ? '#108ee9' : undefined }"
                  />
                </template>
                <template #customRender="{ text, column }">
                  <span
                    v-if="searchText && searchedColumn === column.dataIndex"
                  >
                    <template
                      v-for="(fragment, i) in text
                        .toString()
                        .split(
                          new RegExp(
                            `(?<=${searchText})|(?=${searchText})`,
                            'i'
                          )
                        )"
                    >
                      <mark
                        v-if="
                          fragment.toLowerCase() === searchText.toLowerCase()
                        "
                        :key="i"
                        class="highlight"
                      >
                        {{ fragment }}
                      </mark>
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </span>
                  <template v-else>
                    {{ text }}
                  </template>
                </template>

                <template #lastAuthDate="{ record }">
                  <span>{{ record.last_login_diff }} </span>
                </template>
                <template #lastAuthCount="{ record }">
                  <span v-if="record.login_count > 0">
                    <a-badge
                      :count="record.login_count"
                      :number-style="{
                        backgroundColor: '#5f63f215',
                        color: '#5f63f2'
                      }"
                    /></span>
                  <span v-else />
                </template>
              </a-table>
            </sdCards>
          </a-col>
        </a-row>
      </Main>
    </a-spin>
  </div>
</template>
<script>
import { Main } from './../styled'
import { SearchOutlined } from '@ant-design/icons-vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    Main,
    SearchOutlined,
  },
  data() {
    return {
      customers: [],
      searchText: '',
      searchedColumn: '',
      isLoading: true,
      columns: [
        {
          title: 'İsim',
          dataIndex: 'name',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'name',
          },
          onFilter: (value, record) =>
            record.name
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.$refs.searchInput.focus()
              }, 100)
            }
          },
          sorter: (a, b) => a.name.length - b.name.length,
          sortDirections: ['descend'],
        },
        {
          title: 'Son Oturum',
          dataIndex: 'last_login_diff',
          key: 'last_login_diff',
          slots: {
            customRender: 'lastAuthDate',
          },
        },
        {
          title: 'Oturum Sayısı',
          dataIndex: 'last_login_at',
          key: 'last_login_at',
          slots: {
            customRender: 'lastAuthCount',
          },
        },
      ],
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    convertDateFormat(dateStr) {
      const parts = dateStr.split(' ')
      const dateParts = parts[0].split('/')
      return new Date(
        `${dateParts[2]}-${dateParts[1]}-${dateParts[0]} ${parts[1]}`,
      )
    },
    getTimeDifference(pastDate) {
      if ( ! pastDate) return '-'

      const today = new Date()
      const past = this.convertDateFormat(pastDate)
      const diffInTime = today - past
      const diffInMinutes = Math.floor(diffInTime / (1000 * 60))
      const diffInHours = Math.floor(diffInTime / (1000 * 3600))
      const diffInDays = Math.floor(diffInHours / 24)

      if (diffInDays === 0) {
        if (diffInHours === 0) {
          return diffInMinutes === 1
            ? '1 dakika önce'
            : `${diffInMinutes} dakika önce`
        }
        return diffInHours === 1 ? '1 saat önce' : `${diffInHours} saat önce`
      } else if (diffInDays < 7) {
        return diffInDays === 1 ? '1 gün önce' : `${diffInDays} gün önce`
      } else if (diffInDays < 30) {
        const weeks = Math.floor(diffInDays / 7)
        return weeks === 1 ? '1 hafta önce' : `${weeks} hafta önce`
      } else {
        const months = Math.floor(diffInDays / 30)
        return months === 1 ? '1 ay önce' : `${months} ay önce`
      }
    },
    get() {
      this.isLoading = true
      this.$restMethods
        .postForNoc('/get-customers')
        .then(response => {
          if ( ! response.hasError()) {
            this.customers = response.getData().customers.map(customer => {
              customer.last_login_diff = this.getTimeDifference(
                customer.last_login_at,
              )
              return customer
            })
          }
          this.isLoading = false
        })
    },
  },
})
</script>
<style>
.ant-layout-footer {
  margin-top: 350px;
}
</style>